(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        // Lazyload
        $('.lazyload').Lazy({
            effect: 'fadeIn',
            visibleOnly: true,
            onError: function (element) {
                console.log('error loading ' + element.data('src'));
            }
        });

        // Phone Concatenation Script For Tracking
        setTimeout(function () {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 2000)
    });

    var resizeTimer;
    $(window).bind('resize load', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {

            $('.messages .slider, .testimonials .slider').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                arrows: false,
                dots: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ]
            });

            $('.rooms .slider, .brands .slider, .homes .slider').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                arrows: false,
                dots: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                ]
            });

        }, 500)
    })

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    // Smooth Scroll To Anchor
    $(document).on('click', 'a[href*="#"]', function (event) {
        event.preventDefault()
        var target = $(this).attr('href')

        if ($(target).length) {
            if ($(this).hasClass("open-popup")) {
                $(target).fadeIn(500);
                $("html, body").addClass("scroll-lock");
            } else if ($(this).hasClass("close-popup")) {
                $(target).fadeOut(500);
                $("html, body").removeClass("scroll-lock");
            } else {
                $("html, body").animate( {
                    scrollTop: $(target).offset().top - 80,
                }, 1500 );
            }
        }
    });
    $(document).on('click', 'div.close-popup', function (event) {
        event.preventDefault()
        var target = $(this).attr('data-close');
        console.log(target);
        $(target).fadeOut(500);
        $("html, body").removeClass("scroll-lock");
    });

})( jQuery );

//Get Current Month Name
function getCurrentMonth() {
    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var now       = new Date();
    var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
    var output = document.getElementsByClassName('output');

    $( ".output" ).html( thisMonth);
}
getCurrentMonth();
